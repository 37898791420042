import { NextApiRequest } from 'next';

const isTokenInCookies = (cookies: string): boolean => {
  return cookies.split('; ').some(x => x.indexOf('token=') === 0);
};

export function isLoggedIn(req?: NextApiRequest): boolean {
  // Client side: Access cookie from document
  if (typeof window !== 'undefined') {
    return isTokenInCookies(document.cookie);
  }

  // Server side: from headers
  if (req?.headers?.cookie) {
    return isTokenInCookies(req?.headers.cookie);
  }
  return false;
}
