import { useCallback, useContext } from 'react';

import { ModalDispatchContext } from '../context/modalContext';
import { WEB_APP_URL } from '../globals';
import { isLoggedIn } from '../utils';

export const useSignUpHandler = () => {
  const setModalState = useContext(ModalDispatchContext);
  return useCallback(() => {
    if (isLoggedIn()) {
      window.location.href = WEB_APP_URL;
      return;
    }

    setModalState({ signupVisible: true });
  }, [setModalState]);
};
