import { Styles } from 'react-modal';

export const modalStyle: Styles = {
  overlay: {
    background: 'rgba(25,23,54,.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
  },
  content: {
    background: 'transparent',
    border: 'none',
    position: 'relative',
    margin: 0,
    padding: 0,
    inset: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
