import Image from 'next/image';

import { Assets } from '../../../../utils/assets';
import IconImage from './assets/download_mac_app_logo.webp';
import * as S from './styles';

type Props = {
  onClose: () => void;
  onNavigate: () => void;
};

export const DownloadMacAppBanner = ({ onClose, onNavigate }: Props) => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.ImageContainer>
          <Image alt="Download Mac App" height={76} src={IconImage} width={172} />
        </S.ImageContainer>

        <S.TextContainer>
          <S.Title>Introducing the Brain.fm Mac App</S.Title>
          <S.Subtitle>
            No more juggling browser tabs-just seamless, distraction-free productivity.
          </S.Subtitle>
        </S.TextContainer>

        <S.ButtonContainer>
          <S.Button onClick={onNavigate}>download brain.fm for desktop</S.Button>
          <S.ButtonSmall onClick={onNavigate}>download</S.ButtonSmall>
          <S.CloseButton>
            <Image
              alt="Close"
              height={24}
              src={Assets.images.closeIcon.url}
              width={24}
              onClick={onClose}
            />
          </S.CloseButton>
        </S.ButtonContainer>
      </S.Container>
      <S.BackgroundBlurOne
        alt={Assets.images.circleOfBlurs.alt}
        src="https://cdn.brain.fm/images/circle_of_blurs2.webp"
      />
      <S.BackgroundBlurTwo
        alt={Assets.images.circleOfBlurs.alt}
        src="https://cdn.brain.fm/images/circle_of_blurs2.webp"
      />
    </S.Wrapper>
  );
};
