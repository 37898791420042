import { DefaultTheme } from 'styled-components';

const colors = {
  background: '#0D0C11',
  white: '#ffffff',
  white50 : 'rgba(255, 255, 255, 0.5)',
  white75 : 'rgba(255, 255, 255, 0.75)',
  textGray: '#D4D2EA',
  brandPrimary: 'rgb(73, 18, 246)',
  brandPrimaryDark: '#310F92',
  brandSecondary: '#F69B14',
  brandSecondaryDark: '#F65809',
  blueLight: '#0D99FF',
};

export type ColorsType = typeof colors;

const fontSize = {
  extraSmall: '0.75rem',
  small: '1em',
  medium: '1.25em',
  large: '1.5em',
  extraLarge: '1.75em',
  h1: '2.2em',
};

const fontFamily = {
  regular: 'TTNormsPro-Regular',
  medium: 'TTNormsPro-Medium',
  bold: 'TTNormsPro-Bold',
};

const theme: DefaultTheme = {
  colors,
  fontFamily,
  fontSize,
};

export const newTheme: DefaultTheme = {
  colors: {
    background: 'rgba(25, 23, 54, 1)',
    blueLight: '#0D99FF',
    white: '#ffffff',
    white50: 'rgba(255, 255, 255, 0.5)',
    white75 : 'rgba(255, 255, 255, 0.75)',
    textGray: '#D4D2EA',
    // brandPrimary: '#3F17F0',
    brandPrimary: '#192a56',
    brandPrimaryDark: '#2f3640',
    brandSecondary: '#B53471',
    brandSecondaryDark: '#6F1E51',
  },
  fontFamily,
  fontSize,
};

export default theme;

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: ColorsType;
    fontFamily: typeof fontFamily;
    fontSize: typeof fontSize;
  }
}
