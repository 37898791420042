import React from 'react';

import { AnalyticsEvent } from '../../utils/analytics';
import { PrimaryButtonContainer } from './Primary';
import { SecondaryButtonContainer } from './Secondary';

type Props = {
  analyticsEvent: AnalyticsEvent;
  children: React.ReactNode;
  href?: string;
  onClick?: () => void;
  isAnchortag?: boolean;
  target?: string;
  rel?: string;
  variant?: 'light' | 'dark';
};

export const PrimaryButtonLink: React.FC<Props> = ({
  children,
  analyticsEvent,
  href,
  onClick,
  target,
  rel,
  isAnchortag = true,
  variant = 'light',
}) => {
  return (
    <PrimaryButtonContainer
      as={isAnchortag ? 'a' : undefined}
      data-analytics-event={JSON.stringify(analyticsEvent)}
      href={href}
      rel={rel}
      target={target}
      variant={variant}
      onClick={onClick}
    >
      {children}
    </PrimaryButtonContainer>
  );
};

export const SecondaryButtonLink: React.FC<Props> = ({
  children,
  analyticsEvent,
  href,
  onClick,
  target,
  rel,
  isAnchortag = true,
}) => {
  return (
    <SecondaryButtonContainer
      as={isAnchortag ? 'a' : undefined}
      data-analytics-event={JSON.stringify(analyticsEvent)}
      href={href}
      rel={rel}
      target={target}
      onClick={onClick}
    >
      {children}
    </SecondaryButtonContainer>
  );
};
