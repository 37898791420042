import styled, { css, keyframes } from 'styled-components';

import { mediaQuery } from '../../styles/global';

const FadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 99;
`;

export const HeaderDiv = styled.header<{isScrolled: boolean}>`
  display: flex;
  flex-direction: row;
  color: #ecf0f1;
  padding: 1rem;
  min-height: 5rem;
  width: 100%;
  z-index: 10;
  font-size: 12px;
  position: relative;

  // Transition on linear gradient does not work, workaround
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(13, 12, 17, 1) 80%, transparent);
    opacity: ${({ isScrolled }) => (isScrolled ? 1 : 0)};
    transition: opacity 0.3s ease-in-out;
    z-index: -1;
  }

  ${mediaQuery.minWidth.desktop} {
    padding: 1.25rem 2rem;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 1;
`;

export const RightContainer = styled.div`
  display: flex;
  // flex: 2;
  justify-content: flex-end;
  flex-direction: row;
`;

export const NavigationScience = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: middle;
  margin-left: 2.5rem;
  position: relative;

  ${props =>
    props.isActive &&
    css`
      &:after {
        animation: ${FadeInAnimation} 0.3s forwards;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        height: 3px;
        border-radius: 10px;
      }
    `}

  ${mediaQuery.maxWidth.tablet} {
    display: none;
  }
`;

export const NavigationAbout = styled(NavigationScience)`
  @media (width <= 875px) {
    display: none;
  }
`;

export const NavigationGift = styled(NavigationAbout)`
  @media (width <= 910px) {
    display: none;
  }
`;

export const NavigationPricing = styled.div`
  display: flex;
  align-items: middle;
  margin-left: 2rem;

  ${mediaQuery.maxWidth.desktop} {
    display: none;
  }
`;
