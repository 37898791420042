import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: TTNormsPro-Regular;
    src: url('/fonts/TTNormsPro-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: TTNormsPro-Medium;
    src: url('/fonts/TTNormsPro-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: TTNormsPro-Bold;
    src: url('/fonts/TTNormsPro-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
  }

  @keyframes simpleFadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @-webkit-keyframes autofill {
    to {
        color: white;
        background: transparent;
    }
  }

  html,
  body {
    height: 100%;
    min-width: 320px;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: 'TTNormsPro-Medium';
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.textGray};
  }

  * {
    box-sizing: border-box;
  }

  #__next {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }

  .custom-toast {
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
    background: rgba(255, 255, 255, 0.1);
    border-radius: 1.25rem;
    border: rgba(255, 255, 255, 0.75);
    padding: 1rem;
    color: #ffffff;
  }
`;

const customMediaQuery = (str: string, width: number): string =>
  `@media (${str}-width: ${width}px)`;

export const Breakpoints = {
  wide: 3300,
  desktop: 1100,
  tablet: 768,
  phone: 576,
};

export const mediaQuery = {
  minWidth: {
    custom: (width: number): string => customMediaQuery('min', width),
    wide: customMediaQuery('min', Breakpoints.wide),
    desktop: customMediaQuery('min', Breakpoints.desktop),
    tablet: customMediaQuery('min', Breakpoints.tablet),
    phone: customMediaQuery('min', Breakpoints.phone),
  },
  maxWidth: {
    custom: (width: number): string => customMediaQuery('max', width),
    wide: customMediaQuery('max', Breakpoints.wide),
    desktop: customMediaQuery('max', Breakpoints.desktop),
    tablet: customMediaQuery('max', Breakpoints.tablet),
    phone: customMediaQuery('max', Breakpoints.phone),
  },
};

export default GlobalStyles;
