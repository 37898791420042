import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { ANDROID_DOWNLOAD_LINK, IOS_DOWNLOAD_LINK } from '../../globals';
import { useMobileDetector } from '../../hooks';
import { AnalyticsEvent } from '../../utils/analytics';

const visible = css`
  opacity: 1;
  visibility: visible;
`;

const Container = styled.a`
  background: linear-gradient(90deg, #ea9e3d 30%, #ee7a38);
  padding: 1rem 2.75rem;
  border-radius: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  outline: none;
  border: none;
`;

const MobileDownloadApp = styled.div<{ isVisible: boolean }>`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background: linear-gradient(0deg, #4912f6 70%, rgba(73, 18, 246, 0));
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  ${({ isVisible }) => (isVisible ? visible : '')}
`;

const TextSmall = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.7rem;
`;

const TextLarge = styled(TextSmall)`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

interface DownloadAppProps {
  analyticsEvent: AnalyticsEvent;
}

export const DownloadApp: React.FC<DownloadAppProps> = ({ analyticsEvent }) => {
  const { isIos, isAndroid } = useMobileDetector();
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const handleTouchEvent = () => {
      setVisible(true);
    };

    document.addEventListener('touchmove', handleTouchEvent);

    return () => document.removeEventListener('touchmove', handleTouchEvent);
  }, []);

  let link = '/';
  if (isIos) link = IOS_DOWNLOAD_LINK;
  if (isAndroid) link = ANDROID_DOWNLOAD_LINK;

  return (
    <MobileDownloadApp isVisible={isVisible}>
      <Container data-analytics-event={JSON.stringify(analyticsEvent)} href={link}>
        <TextLarge>Download our app</TextLarge>
        <TextSmall>Get your first 3 days free!</TextSmall>
      </Container>
    </MobileDownloadApp>
  );
};
