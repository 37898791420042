import { Router, useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { ModalDispatchContext } from '../../context/modalContext';
import {
  ANDROID_DOWNLOAD_LINK,
  IOS_DOWNLOAD_LINK,
  WEB_APP_SIGNIN_URL,
  WEB_APP_URL,
} from '../../globals';
import { useSignUpHandler } from '../../hooks/useSignUpHandler';
import { PromoBannerType } from '../../pages/api/promoBanner';
import { isLoggedIn } from '../../utils';
import { DOWNLOAD_MAC_BANNER_OR_MODAL } from '../../utils/ABTests';
import { trackDownloadClick } from '../../utils/analytics';
import { DeviceTypes } from '../../utils/getDeviceType';
import { useLocalABTest } from '../../utils/useLocalABTest';
import { PartnerType } from './components/PartnerBanner';
import { HeaderDisplay } from './Header.display';

export function HeaderContainer(props: {
  deviceType: DeviceTypes;
  promoBanner: PromoBannerType | null;
}): JSX.Element {
  const onSignUp = useSignUpHandler();
  const isUserLoggedIn = isLoggedIn();
  const setModalState = useContext(ModalDispatchContext);
  const [partner, setPartner] = useState<PartnerType | null>(null);
  const [isScrolled, setIsScrolled] = useState(false);

  // had to type cast because NextRouter doesn't have "components" property
  const router = useRouter() as Router;

  const [downloadBannerVisible, setDownloadBannerVisible] = useState(false);

  const downloadMacTest = useLocalABTest(DOWNLOAD_MAC_BANNER_OR_MODAL);

  useEffect(() => {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

    if (isMac && downloadMacTest === 'banner' && !partner && !props.promoBanner) {
      const id = setTimeout(() => {
        setDownloadBannerVisible(true);
      }, 3000);

      return () => {
        clearTimeout(id);
      };
    }

    if (isMac && downloadMacTest === 'modal') {
      setModalState({ downloadMacAppVisible: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadMacTest, partner, props.promoBanner]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (router.pathname === '/[partner]') {
      const partnerComponent = router.components[router.pathname];
      setPartner(partnerComponent.props?.pageProps?.partner);
    } else {
      setPartner(null);
    }
  }, [router.components, router.pathname]);

  function handleCloseDownloadBanner() {
    setDownloadBannerVisible(false);
  }

  function handleScroll() {
    setIsScrolled(window.scrollY > 50);
  }

  function handleDownloadMacApp() {
    handleCloseDownloadBanner();
    router.push('/downloadA');
  }

  function handleClickSignIn() {
    window.location.href = WEB_APP_SIGNIN_URL;
  }

  function handleClickDownload() {
    trackDownloadClick();

    if (props.deviceType === DeviceTypes.Android) {
      window.location.href = ANDROID_DOWNLOAD_LINK;
    } else if (props.deviceType === DeviceTypes.IOS) {
      window.location.href = IOS_DOWNLOAD_LINK;
    }
  }

  const onNavigateToApp = useCallback(() => {
    window.location.href = WEB_APP_URL;
  }, []);

  const shouldHideHeaderActions = Boolean(partner);

  return (
    <HeaderDisplay
      deviceType={props.deviceType}
      downloadMacAppBannerVisible={downloadBannerVisible}
      headerActionsAvailable={!shouldHideHeaderActions}
      isDownloadPage={router.pathname === '/download'}
      isScrolled={isScrolled}
      isUserLoggedIn={isUserLoggedIn}
      partner={partner}
      promoBanner={props.promoBanner}
      onClickDownload={handleClickDownload}
      onClickSignIn={handleClickSignIn}
      onClickSignUp={onSignUp}
      onDownloadMacAppClose={handleCloseDownloadBanner}
      onDownloadMacAppNavigate={handleDownloadMacApp}
      onNavigateToApp={onNavigateToApp}
    />
  );
}
