import { IncomingMessage } from 'http';

export enum DeviceTypes {
  Android = 'Android',
  Desktop = 'Desktop',
  IOS = 'IOS',
}

export function getDeviceType(params: { request?: IncomingMessage }): DeviceTypes {
  const isBrowser = typeof window !== 'undefined';
  const userAgent = isBrowser ? navigator.userAgent : params.request?.headers['user-agent'];
  if (!userAgent) return DeviceTypes.Desktop;

  if (userAgent.includes('Android')) {
    return DeviceTypes.Android;
  }
  if (userAgent.includes('iPhone') || userAgent.includes('iPad') || userAgent.includes('iPod')) {
    return DeviceTypes.IOS;
  }

  return DeviceTypes.Desktop;
}
