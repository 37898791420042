import React, { useContext } from 'react';
import Modal from 'react-modal';

import { ModalDispatchContext } from '../../context/modalContext';
import { SignupForm } from '../forms/SignupForm';

Modal.setAppElement('#__next');

interface SignupFormModalProps {
  /** Is modal open? */
  isOpen: boolean;
}

import { Styles } from 'react-modal';
import styled from 'styled-components';

import { Assets } from '../../utils/assets';
import { FormCloseButton } from '../forms/FormCloseButton';

export const modalStyle: Styles = {
  overlay: {
    background: '#030303CC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
  },
  content: {
    background: 'transparent',
    border: 'none',
    margin: 0,
    padding: 0,
    inset: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  max-width: calc(100vw - 20px);
  min-height: 100vh;
`;

const Overlay = styled.div`
  background: url(${Assets.images.signUpModalOverlay.url}) no-repeat;
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
`;

export const SignupFormModal: React.FC<SignupFormModalProps> = ({ isOpen }) => {
  const setModalState = useContext(ModalDispatchContext);

  const onClose = () => {
    setModalState({ signupVisible: false });
  };

  return (
    <Modal isOpen={isOpen} style={modalStyle} onRequestClose={onClose}>
      <Overlay />
      <ContentWrapper>
        <FormCloseButton
          analyticsEvent={{ category: 'Signup Form', action: 'Close Form Button' }}
          onClick={onClose}
        />
        <SignupForm isModal onClose={onClose} />
      </ContentWrapper>
    </Modal>
  );
};
