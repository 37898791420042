import type { CredentialResponse } from 'google-one-tap';
import { GsiButtonConfiguration } from 'google-one-tap';
import { useEffect } from 'react';

import { GOOGLE_CLIENT_ID } from '../globals';

type Props = {
  callback: (credentialResponse: CredentialResponse) => void;
  clickListener?: () => void;
  context?: 'signin' | 'signup' | 'use';
  shouldPrompt?: boolean;
  buttonStyles?: Partial<GsiButtonConfiguration>;
  elementId?: string;
};

export const useInitializeGoogleAuth = (props: Props) => {
  const { callback, clickListener, context, shouldPrompt, buttonStyles, elementId } = props;
  const dep = typeof google;

  useEffect(() => {
    if (typeof google !== 'undefined') {
      const googleBtn = document.getElementById(elementId || 'google-button');
      google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback,
        context: context || 'signup',
        use_fedcm_for_prompt: true,
      });
      google.accounts.id.renderButton(googleBtn, {
        theme: 'filled_blue',
        shape: 'pill',
        size: 'large',
        logo_alignment: 'left',
        // @ts-ignore
        click_listener: clickListener,
        ...buttonStyles,
      });
      if (shouldPrompt) {
        google.accounts.id.prompt();
      }
    }
  }, [dep, buttonStyles, callback, clickListener, elementId, context, shouldPrompt]);
};
