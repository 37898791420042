import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.extraSmall};
  text-align: center;
  margin: 0.5rem;
  line-height: 1rem;
`;

const Content = styled.div`
  display: flex;
  font-size: 0.7rem;
  color: rgba(255, 255, 255, 0.75);
  padding: 0.4rem;
  text-transform: uppercase;
`;
const Bar = styled.div`
  display: flex;
  flex-grow: 1;
  height: 2px;
  background: rgba(55, 62, 91, 0.7);
`;

interface Props {
  type: string;
}

export const OtherOptions: React.FC<Props> = ({ type }) => {
  return (
    <Wrapper>
      <Bar />
      <Content>Or {type} with</Content>
      <Bar />
    </Wrapper>
  );
};
