import styled from 'styled-components';

import { Assets } from '../../utils/assets';
import { MediaLink } from './MediaLink';

const links = [
  {
    name: Assets.icons.facebook.alt,
    link: 'https://www.facebook.com/brainfm',
    analyticsEvent: { category: 'Footer', action: 'Facebook Profile Link Click' },
    iconPath: Assets.icons.facebook.url,
  },
  {
    name: Assets.icons.twitter.alt,
    link: 'https://x.com/BrainfmApp',
    analyticsEvent: { category: 'Footer', action: 'Twitter Profile Link Click' },
    iconPath: Assets.icons.twitter.url,
  },
  {
    name: Assets.icons.instagram.alt,
    link: 'https://www.instagram.com/brainfmapp/',
    analyticsEvent: { category: 'Footer', action: 'Instagram Profile Link Click' },
    iconPath: Assets.icons.instagram.url,
  },
];

const Container = styled.div`
  display: flex;
`;

export const MediaLinks: React.FC = () => {
  return (
    <Container>
      {links.map(({ name, link, iconPath, analyticsEvent }) => (
        <MediaLink
          key={name}
          analyticsEvent={analyticsEvent}
          iconPath={iconPath}
          link={link}
          name={name}
        />
      ))}
    </Container>
  );
};
