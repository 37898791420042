/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';

export type UseLocalABTestParams = {
  fallback: string;
  key: string;
  version: number;
  tests: LocalABTest[];
};

type LocalABTest = {
  percentage: number;
  value: string;
};

export function useLocalABTest(params: UseLocalABTestParams, _window = global.window): string {
  validateTestParameters(params);
  useCleanupPreviousTests(params);

  const result = useMemo(() => {
    if (!_window) return params.fallback;
    return getSavedABTestResult(params, _window) || getTestResult(params) || params.fallback;
  }, []);

  useEffect(() => {
    _window?.localStorage?.setItem(getStorageKey(params), result);
  }, [result]);

  return result;
}

export function getSavedABTestResult(params: UseLocalABTestParams, _window = global.window): string | null {
  const key = getStorageKey(params);
  const previousResult = _window?.localStorage?.getItem(key) || null;

  const isPreviousResultValid = params.tests.some(test => test.value === previousResult);
  if (isPreviousResultValid) return previousResult;

  return null;
}

function validateTestParameters(params: UseLocalABTestParams): void {
  const percentageSum = params.tests.reduce((sum, test) => {
    return sum + test.percentage;
  }, 0);
  if (percentageSum !== 1) {
    throw new Error('useLocalABTest(): test percentages must add up to one');
  }

  if (!params.tests.some(test => test.value === params.fallback)) {
    throw new Error('useLocalABTest(): fallback value must be a valid test value');
  }
}

function useCleanupPreviousTests(params: UseLocalABTestParams, _window = global.window): void {
  useEffect(() => {
    for (let i = 1; i < params.version; i++) {
      const keyToCleanup = `${params.key}_${i}`;
      _window?.localStorage?.removeItem(keyToCleanup);
    }
  }, []);
}

function getStorageKey(params: UseLocalABTestParams): string {
  return `${params.key}_${params.version}`;
}

function getTestResult(params: UseLocalABTestParams): string {
  let currentPercentageFloor = 0;
  let result = params.fallback;

  const random = Math.random();

  for (const test of params.tests) {
    currentPercentageFloor += test.percentage;
    if (random < currentPercentageFloor) {
      result = test.value;
      break;
    }
  }

  return result;
}
