import styled, { keyframes } from 'styled-components';

import { PrimaryButtonContainer } from '../../../button/Primary';

const MEDIUM_WIDTH = 880;

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #0d0c11;
  animation: ${FadeIn} 0.5s;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 76px;
  padding-right: 16px;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  isolation: isolate;
  z-index: 99;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;
  flex: 1;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const Title = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  line-height: 24px;
  text-align: left;
  color: #ffffff;
  font-size: clamp(16px, 8vw - 2rem, 20px);
`;

export const Subtitle = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  line-height: 20px;
  text-align: left;
  color: rgba(255, 255, 255, 0.75);
  font-size: clamp(12px, 8vw - 2rem, 14px);
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const Button = styled(PrimaryButtonContainer)`
  padding: 0 20px;
  font-size: 12px;
  line-height: 14.4px;
  letter-spacing: 0.1em;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${MEDIUM_WIDTH}px) {
    display: none;
  }
`;

export const ButtonSmall = styled(Button)`
  @media (min-width: ${MEDIUM_WIDTH}px) {
    display: none;
  }

  @media (max-width: ${MEDIUM_WIDTH}px) {
    display: flex;
  }
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  opacity: 0.5;
  transition: opacity 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const BackgroundBlurOne = styled.img`
  position: absolute;
  bottom: -170%;
  left: -15%;
  width: 515px;
  height: 515px;
  transform: rotate(80deg) scale(1.8);
  z-index: 1;
`;

export const BackgroundBlurTwo = styled.img`
  position: absolute;
  width: 834px;
  height: 834px;
  top: -250%;
  right: 0;
  transform: rotate(155deg) scale(1.5);
  z-index: 1;
`;
