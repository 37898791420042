import React from 'react';

export type State = {
  loginVisible: boolean;
  signupVisible: boolean;
  howItWorksVideoVisible: boolean;
  downloadMacAppVisible: boolean;
};

export type Dispatch = (action: Partial<State>) => void;

export const ModalStateContext = React.createContext<State | undefined>(undefined);
export const ModalDispatchContext = React.createContext<Dispatch | undefined>(undefined);
