/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

interface MobileDetector {
  isMobile: boolean;
  isIos: boolean;
  isAndroid: boolean;
  isEvaluated: boolean;
}

/**
 * Hook to determine if the device is mobile
 */
export const useMobileDetector = (): MobileDetector => {
  const [_navigator, setNavigator] = useState({ userAgent: '' });
  const [state, setState] = useState({ isMobile: false, isIos: false, isAndroid: false, isEvaluated: false });

  useEffect(() => {
    if (typeof navigator !== 'undefined') {
      setNavigator({ userAgent: navigator.userAgent });
    }
  }, []);

  useEffect(() => {
    const UA = navigator.userAgent;

    const isIos = /\b(iPhone|iPad|iPod)\b/i.test(UA);
    const isAndroid = /Android/i.test(UA);

    setState({
      isMobile: isIos || isAndroid,
      isAndroid,
      isIos,
      isEvaluated: true,
    });
  }, [_navigator.userAgent]);

  return state;
};
