import styled, { css } from 'styled-components';

import { mediaQuery } from '../../styles/global';
import Button, { ButtonProps } from '../button/Button';

export const PrimaryButtonContainer = styled(Button)<{ hasBorder: boolean }>`
  height: 52px;
  position: relative;
  color: #fff;
  border-radius: 4rem;
  padding: 0px 1.8rem;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  letter-spacing: 0.08em;
  text-align: center;
  transition: all 0.2s linear;

  ${({ hasBorder }) => {
    if (hasBorder) {
      return css`
        &:hover {
          box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset;
        }

        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.1) 0%,
          rgba(255, 255, 255, 0.01) 100%
        );
        box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset;
      `;
    }

    return css`
      background: transparent;
    `;
  }}

  ${mediaQuery.maxWidth.tablet} {
    height: 40px;
    font-size: 12px;
    padding: 0px 1.25rem;
  }
`;

export const HeaderActionButton: React.FC<
  ButtonProps & { hasBorder?: boolean; testId?: string }
> = ({ analyticsEvent, children, testId, hasBorder = true, onClick }) => {
  return (
    <PrimaryButtonContainer
      data-analytics-event={JSON.stringify(analyticsEvent)}
      data-testid={testId}
      hasBorder={hasBorder}
      suppressHydrationWarning
      onClick={onClick}
    >
      {children}
    </PrimaryButtonContainer>
  );
};
