import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

import { mediaQuery } from '../../styles/global';
import { AnalyticsEvent } from '../../utils/analytics';
import { Assets } from '../../utils/assets';

const ImageWrapper = styled.div`
  display: flex;
  cursor: pointer;
  width: 158px;
  height: 48px;
  justify-content: center;

  ${mediaQuery.maxWidth.tablet} {
    width: 141px;
    height: 40px;
  }
`;

const ImageLogo = styled.img`
  width: 100%;
  object-fit: contain;
`;

interface BrandLogoProps {
  analytics?: AnalyticsEvent;
}

export const BrandLogo: React.FC<BrandLogoProps> = ({ analytics }) => {
  return (
    <Link href="/" passHref>
      <ImageWrapper data-analytics-event={JSON.stringify(analytics)}>
        <ImageLogo alt={Assets.images.logoFull.alt} src={Assets.images.logoFull.url} />
      </ImageWrapper>
    </Link>
  );
};
