import Link from 'next/link';
import styled from 'styled-components';

const TextContainer = styled.div`
  font-size: 0.68rem;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  text-align: center;
  margin: 1rem;
  line-height: 1rem;
`;

const StyledLink = styled.a`
  text-decoration: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 0.68rem;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  cursor: pointer;
`;

export const Disclaimer: React.FC = () => {
  return (
    <TextContainer>
      By creating an account, you agree to Brain.fm&apos;s{' '}
      <Link href="/terms" legacyBehavior>
        <StyledLink
          data-analytics-event={JSON.stringify({
            category: 'Disclaimer Form',
            action: 'Terms Page Link Click',
          })}
        >
          Terms & Conditions
        </StyledLink>
      </Link>{' '}
      and{' '}
      <Link href="privacy" legacyBehavior>
        <StyledLink
          data-analytics-event={JSON.stringify({
            category: 'Disclaimer Form',
            action: 'Privacy Page Link Click',
          })}
        >
          Privacy Policy
        </StyledLink>
      </Link>
      .
    </TextContainer>
  );
};
