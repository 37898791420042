import styled from 'styled-components';

import Button, { ButtonProps } from './Button';

export const GhostButtonContainer = styled(Button)`
  position: relative;
  border-radius: 4rem;
  padding: 0.8rem 2rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);
  background-clip: padding-box;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4rem;
    padding: 1px; /* Border thickness */
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.01));
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    mask-composite: exclude; 
    -webkit-mask-composite: xor;
    z-index: -1;
  }
`;

const StretchButton = styled(GhostButtonContainer)`
  flex-grow: 1;
`;

export const GhostButton: React.FC<ButtonProps> = ({
  children,
  style,
  analyticsEvent,
  onClick,
  isLoading = false,
}) => {
  return (
    <GhostButtonContainer
      data-analytics-event={JSON.stringify(analyticsEvent)}
      isLoading={isLoading}
      style={style}
      onClick={isLoading ? undefined : onClick}
    >
      {isLoading ? <Loading /> : children}
    </GhostButtonContainer>
  );
};

export const GhostButtonWide: React.FC<ButtonProps> = ({
  children,
  analyticsEvent,
  onClick,
  isLoading = false,
}) => {
  return (
    <StretchButton
      data-analytics-event={JSON.stringify(analyticsEvent)}
      isLoading={isLoading}
      onClick={isLoading ? undefined : onClick}
    >
      {isLoading ? <Loading /> : children}
    </StretchButton>
  );
};

// quick and easy because i had five minutes
// - dan p <3
const LoadingContainer = styled.div`
  animation: shimmer 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;

  @keyframes shimmer {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

function Loading() {
  return <LoadingContainer>Submitting...</LoadingContainer>;
}
