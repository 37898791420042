import Image from 'next/image';
import styled from 'styled-components';

import { Assets } from '../../utils/assets';

export const CloseIconWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  margin: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 100%;
  transition: background 0.2s ease-in-out;
  z-index: 1;

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const FormCloseButton = ({ analyticsEvent, onClick, ...props }) => {
  return (
    <CloseIconWrapper
      data-analytics-event={JSON.stringify(analyticsEvent)}
      onClick={onClick}
      {...props}
    >
      <Image
        alt={Assets.images.closeIcon.alt}
        height={30}
        src={Assets.images.closeIcon.url}
        width={30}
      />
    </CloseIconWrapper>
  );
};
