import styled from 'styled-components';

import { mediaQuery } from '../../styles/global';
import Button, { ButtonProps } from './Button';

export const SecondaryButtonContainer = styled(Button)`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);
  border-radius: 4rem;
  padding: 0.8rem 2rem;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  letter-spacing: 1px;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.white};
  border: 1px solid rgba(255, 255, 255, 0.1);

  ${mediaQuery.maxWidth.tablet} {
    font-size: 0.65rem;
  }
`;

export const SecondaryButton: React.FC<ButtonProps> = ({ children, analyticsEvent, onClick }) => {
  return (
    <SecondaryButtonContainer
      data-analytics-event={JSON.stringify(analyticsEvent)}
      onClick={onClick}
    >
      {children}
    </SecondaryButtonContainer>
  );
};
