import Image from 'next/image';
import styled from 'styled-components';

import { ANDROID_DOWNLOAD_LINK, IOS_DOWNLOAD_LINK } from '../../globals';
import { mediaQuery } from '../../styles/global';
import { Assets } from '../../utils/assets';

const links = [
  {
    imgPath: Assets.images.listenMyBrainFM.url,
    name: Assets.images.listenMyBrainFM.alt,
    link: 'https://my.brain.fm',
    analyticsEvent: { category: 'Footer', action: 'Listen Now - MyBrainFM' },
    shouldNotFollow: true,
  },
  {
    imgPath: Assets.images.listenApple.url,
    name: Assets.images.listenApple.alt,
    link: IOS_DOWNLOAD_LINK,
    analyticsEvent: { category: 'Footer', action: 'Listen Now - Apple' },
  },
  {
    imgPath: Assets.images.listenGoogle.url,
    name: Assets.images.listenGoogle.alt,
    link: ANDROID_DOWNLOAD_LINK,
    analyticsEvent: { category: 'Footer', action: 'Listen Now - Google' },
  },
];

const Link = styled.a`
  // display: block;
  margin: 0;
  padding: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 46px;

  ${mediaQuery.maxWidth.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const DownloadLinks: React.FC = () => {
  return (
    <Container>
      {links.map(({ name, link, imgPath, analyticsEvent, shouldNotFollow }, index) => (
        <Link
          key={index}
          data-analytics-event={JSON.stringify(analyticsEvent)}
          href={link}
          rel={`noopener noreferrer${shouldNotFollow ? ' nofollow' : ''}`}
          target="_blank"
        >
          <Image alt={name} height={46} loading="lazy" src={imgPath} width={157} />
        </Link>
      ))}
    </Container>
  );
};
