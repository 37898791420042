import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  transition: height 0.3s ease;
`;

export const Image = styled.img`
  height: 1.4rem;
  object-fit: contain;
`;

export const Message = styled.span`
  margin: 0 1rem;
`;
