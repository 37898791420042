export const API_BASE_URL = process.env.BROWSER_API_BASE_URL;
export const API_V3_BASE_URL = process.env.BROWSER_API_V3_BASE_URL;

export const WEB_APP_URL = process.env.BROWSER_WEB_APP_URL;
export const WEB_APP_ONBOARDING_URL = `${WEB_APP_URL}/welcome`;
export const WEB_APP_SIGNUP_URL = WEB_APP_URL;
export const WEB_APP_SIGNIN_URL = `${WEB_APP_URL}/signin`;

export const SELF_URL = process.env.BROWSER_SELF_URL;

export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;
export const HELPSCOUT_API_KEY = process.env.BROWSER_HELPSCOUT_API_KEY;

export const CONTENTFUL_SPACE_ID = process.env.CONTENTFUL_SPACE_ID;
export const CONTENTFUL_DELIVERY_ACCESS_TOKEN = process.env.CONTENTFUL_DELIVERY_ACCESS_TOKEN;

export const CDN_URL = 'https://cdn.brain.fm';

export const FACEBOOK_APP_ID = process.env.BROWSER_FACEBOOK_APP_ID;
export const FACEBOOK_PIXEL_ID = process.env.BROWSER_FACEBOOK_PIXEL_ID;

// development, staging, production
export const BROWSER_ENVIRONMENT = process.env.BROWSER_ENVIRONMENT;

export const BROWSER_GA_TRACKING_ID = process.env.BROWSER_GA_TRACKING_ID;

export const BROWSER_AMPLITUDE_ID = process.env.BROWSER_AMPLITUDE_ID;

export const IOS_DOWNLOAD_LINK =
  'https://itunes.apple.com/us/app/brain-fm-music-for-the-brain/id1110684238?mt=8';
export const ANDROID_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=com.brainfm.app&hl=en_US';

export const APPLE_URL =
  'https://itunes.apple.com/vn/app/brain.fm-music-for-focus-meditation/id1110684238?mt=8';
export const GOOGLE_URL =
  'https://play.google.com/store/apps/details?id=com.brainfm.app&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';

export const GIFT_URL =
  'https://giftup.app/place-order/e382fe5e-19a6-4be5-936e-1bda244f95aa?platform=hosted';

export const MAILING_ADDRESS = `BrainFM, Inc
144 North 7th st
Brooklyn NY 11249`;

export const BRAZE_API_KEY = process.env.BROWSER_BRAZE_API_KEY;
export const BRAZE_SDK_ENDPOINT = 'sdk.iad-03.braze.com';

export const WHITE_PAPER_LINK = `${SELF_URL}/pdfs/white-paper.pdf`;
