import { useField } from 'formik';
import Image from 'next/image';
import { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { Assets } from '../../utils/assets';

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
`;

const InputField = styled.input<{ center: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  padding: 1.5rem 2.5rem 1rem 1rem;

  ${({ center }) =>
    center &&
    css`
      padding: 1.25rem 1rem 1.25rem 1rem;
    `}
  font-size: 1rem;
  color: #d4d2ea;
  //border: 1px solid #373e5b;
  border: 0;
  border-radius: 0.75rem;
  margin: 0;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  transition:
    border 0.2s ease-in-out,
    background 0.3s ease-in-out;

  &:focus {
    background: rgba(255, 255, 255, 0.2);
    //box-shadow: 0px 0px 3px #fff;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected,
  &:-internal-autofill-previewed {
    box-shadow: 0 0 0 300px ${({ theme }) => theme.colors.brandPrimary} inset;
    animation-name: autofill;
    animation-fill-mode: both;
    border-radius: 0.75rem;
    font-size: ${({ theme }) => theme.fontSize.medium};
    -webkit-text-fill-color: ${({ theme }) => theme.colors.white};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.8;
  }
`;

const LabelTransition = css`
  transform: translateY(-0.5rem);
  opacity: 0;
  visibility: hidden;
`;

const InputLabel = styled.label<{ hide: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.725rem;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  opacity: 0.75;
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  transition: all 0.2s ease-in-out;

  ${({ hide }) => hide && LabelTransition}
`;

const IconTransition = css`
  transform: translateX(0.5rem) translateY(50%);
  opacity: 0;
`;

const IconWrapper = styled.div<{ hide: boolean }>`
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ hide }) => hide && IconTransition}
`;

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
}

export const Input: React.FC<InputProps> = ({ name, label, ...rest }) => {
  const [field, meta] = useField(name);

  return (
    <InputContainer>
      <InputLabel hide={field.value.length === 0} htmlFor={name}>
        {label}
      </InputLabel>
      <InputField center={field.value.length === 0} id={name} {...rest} />
      <IconWrapper hide={!(meta.touched && meta.error)}>
        <Image
          alt={Assets.icons.warning.alt}
          height={26}
          src={Assets.icons.warning.url}
          title={meta.error}
          width={26}
        />
      </IconWrapper>
    </InputContainer>
  );
};
