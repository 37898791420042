import styled from 'styled-components';

import Button, { ButtonProps } from './Button';

export const PrimaryButtonContainer = styled(Button)<{ variant?: 'light' | 'dark'}>`
  align-items: center;
  background: ${props => (props.variant === 'light' ? 'white' : 'rgba(255, 255, 255, 0.1)')};
  border-radius: 4rem;
  box-shadow: ${props => (props.variant === 'light' ? '0px 2.542509078979492px 11.441289901733398px 0px rgba(147, 35, 102, 0.4)' : 'none')};
  padding: 0.8rem 2rem;
  color: ${props => (props.variant === 'light' ? 'black' : 'white')};;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  letter-spacing: 1px;
  font-size: 0.875rem;
  padding: 1rem 3.5rem;
  text-transform: uppercase;
  gap: 16px;
`;

const StretchButton = styled(PrimaryButtonContainer)`
  flex-grow: 1;
`;

export const PrimaryButton: React.FC<ButtonProps> = ({
  children,
  analyticsEvent,
  onClick,
  isLoading = false,
  testId,
  variant = 'light',
}) => {
  return (
    <PrimaryButtonContainer
      data-analytics-event={JSON.stringify(analyticsEvent)}
      data-testid={testId}
      isLoading={isLoading}
      variant={variant}
      onClick={isLoading ? undefined : onClick}
    >
      {isLoading ? <Loading /> : children}
    </PrimaryButtonContainer>
  );
};

export const PrimaryButtonWide: React.FC<ButtonProps> = ({
  children,
  analyticsEvent,
  onClick,
  isLoading = false,
  testId,
}) => {
  return (
    <StretchButton
      data-analytics-event={JSON.stringify(analyticsEvent)}
      data-testid={testId}
      isLoading={isLoading}
      onClick={isLoading ? undefined : onClick}
    >
      {isLoading ? <Loading /> : children}
    </StretchButton>
  );
};

// quick and easy because i had five minutes
// - dan p <3
const LoadingContainer = styled.div`
  animation: shimmer 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;

  @keyframes shimmer {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

function Loading() {
  return <LoadingContainer>Submitting...</LoadingContainer>;
}
