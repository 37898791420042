import type Contentful from 'contentful';
import { useCallback, useEffect, useState } from 'react';

import theme from '../../../../styles/theme';
import { Assets } from '../../../../utils/assets';
import * as S from './styles';

type Props = {
  data: {
    bannerStyle: Contentful.EntryFields.Object;
    text: string;
    badge?: Contentful.Asset;
    buttonText?: string;
    link?: string;
  };
};

export const PromoBanner = ({ data }: Props) => {
  const { bannerStyle, text, badge, buttonText, link } = data;
  const [isMounted, setIsMounted] = useState(false);

  const onClick = useCallback(() => {
    if (!link) return;
    window.location.assign(link);
  }, [link]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const color = bannerStyle?.fontColor ?? '#FFFFFF';
  const backgroundColor = bannerStyle?.backgroundColor ?? theme.colors.brandPrimary;
  const textAlign = bannerStyle?.textAlign ?? 'initial';

  return (
    <S.Wrapper
      style={{
        backgroundColor,
        cursor: onClick ? 'pointer' : undefined,
        textAlign: textAlign,
      }}
      onClick={onClick}
    >
      {badge && (
        <S.BadgeContainer>
          <S.Image alt={badge.fields.title} src={badge.fields.file.url} />
        </S.BadgeContainer>
      )}
      <S.Container
        style={{
          height: isMounted ? '3rem' : '0',
        }}
      >
        <S.Content>
          <S.Message dangerouslySetInnerHTML={{ __html: text }} style={{ color }} />
          {buttonText && <S.Button>{buttonText}</S.Button>}
        </S.Content>
        <S.BackgroundBlurOne
          alt={Assets.images.circleOfBlurs.alt}
          src="https://cdn.brain.fm/images/circle_of_blurs2.webp"
        />
        <S.BackgroundBlurTwo
          alt={Assets.images.circleOfBlurs.alt}
          src="https://cdn.brain.fm/images/circle_of_blurs2.webp"
        />
      </S.Container>
    </S.Wrapper>
  );
};
