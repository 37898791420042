import styled from 'styled-components';

import { mediaQuery } from '../../../../styles/global';

const MEDIUM_WIDTH = 880;

export const Wrapper = styled.div``;

export const Container = styled.div`
  height: 0;
  transition: height 0.3s ease;
  width: 100%;
  overflow: hidden;
  position: relative;
  isolation: isolate;
  z-index: 99;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 1440px;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  b {
    font-family: ${({ theme }) => theme.fontFamily.bold};
  }
  h3 {
    font-size: 18px;
    display: inline;
    margin-right: 0.75rem;
  }
  p {
    display: inline;
    ${mediaQuery.maxWidth.phone} {
      display: none;
    }
  }
`;

export const BadgeContainer = styled.div`
  max-width: 1440px;
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  height: 80px;
  width: auto;
  z-index: 150;
  left: 0;
`;

export const Message = styled.span`
  margin: 0 1rem;
  padding-left: 100px;
  z-index: 5;
`;

export const Button = styled.div`
  padding: 0 20px;
  font-size: 12px;
  line-height: 14.4px;
  letter-spacing: 0.1em;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 40px;
  color: black;
  z-index: 14;
  font-family: ${({ theme }) => theme.fontFamily.medium};

  @media (max-width: ${MEDIUM_WIDTH}px) {
    display: none;
  }
`;

export const BackgroundBlurOne = styled.img`
  position: absolute;
  bottom: -170%;
  left: -15%;
  width: 515px;
  height: 515px;
  transform: rotate(80deg) scale(1.8);
  z-index: 4;
`;

export const BackgroundBlurTwo = styled.img`
  position: absolute;
  width: 834px;
  height: 834px;
  top: -250%;
  right: 0;
  transform: rotate(155deg) scale(1.5);
  z-index: 4;
`;
