import { AnchorHTMLAttributes } from 'react';
import styled from 'styled-components';

import { AnalyticsEvent } from '../../utils/analytics';
import { Assets } from '../../utils/assets';
import { AuthButton } from './AuthButton';

const Wrapper = styled.a`
  display: flex;
  width: 45%;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: box-shadow 0.4s ease-in-out;
`;

interface AppleButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  label?: string;
  analyticsEvent: AnalyticsEvent;
}

export const AppleButton: React.FC<AppleButtonProps> = ({ label, analyticsEvent, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <AuthButton
        alt={Assets.icons.appleWhite.alt}
        analyticsEvent={analyticsEvent}
        label={label}
        src={Assets.icons.appleWhite.url}
      />
    </Wrapper>
  );
};
