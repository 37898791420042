import { getDeviceId } from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/nextjs';

import { BROWSER_ENVIRONMENT, WEB_APP_ONBOARDING_URL, WEB_APP_URL } from '../globals';

/**
 * Stores access token in a cookie;
 * @param token
 */
export const setToken = (token: string, options: { isOnboarding?: boolean } = {}): void => {
  const expiration = new Date();
  expiration.setFullYear(2030);

  const domain = BROWSER_ENVIRONMENT === 'production' ? 'brain.fm' : 'brainfm.io';

  document.cookie = `token=${token}; expires=${expiration.toUTCString()}; domain=${domain}`;

  try {
    document.cookie = `deviceId=${getDeviceId()}; expires=${expiration.toUTCString()}; domain=${domain}`;
  } catch (error: unknown) {
    Sentry.captureException('setToken(): could not set device id', { extra: { reason: error } });
  }

  window.location.href = options.isOnboarding ? WEB_APP_ONBOARDING_URL : WEB_APP_URL;
};
