import { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ColumnHeader = styled.h3`
  text-transform: uppercase;
  margin: 0px 0px 1.25rem 0px;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  letter-spacing: 0.1em;
  text-align: left;
`;

interface ColumnWithLinksProps {
  title: string;
  children?: ReactNode;
}

export const ColumnWithLinks: React.FC<ColumnWithLinksProps> = ({ title, children }) => {
  return (
    <Container>
      <ColumnHeader>{title}</ColumnHeader>
      {children}
    </Container>
  );
};
