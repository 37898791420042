import type CookiesType from 'cookies';
import Cookies from 'cookies';
import { IncomingMessage, ServerResponse } from 'http';

export type GetSsrABTestParams = {
  fallback: string;
  key: string;
  version: number;
  tests: LocalABTest[];
};

type LocalABTest = {
  percentage: number;
  value: string;
};

export function getSsrABTest(
  params: GetSsrABTestParams,
  request: IncomingMessage,
  response: ServerResponse,
): string {
  const cookiesInstance = new Cookies(request, response);
  const key = getStorageKey(params);
  validateTestParameters(params);
  performCleanupPreviousTests(params, cookiesInstance);

  const result =
    getSavedABTestResultOnServer(params, cookiesInstance) ||
    getTestResult(params) ||
    params.fallback;
  cookiesInstance.set(key, result, { httpOnly: false });
  return result;
}

export function getSavedABTestResultOnServer(
  params: GetSsrABTestParams,
  cookiesInstance: CookiesType,
): string | null {
  const key = getStorageKey(params);
  const previousResult = cookiesInstance.get(key) || null;

  const isPreviousResultValid = params.tests.some(test => test.value === previousResult);
  if (isPreviousResultValid) return previousResult;

  return null;
}

export function getSavedSsrABTestResult(params: GetSsrABTestParams): string | null {
  const key = getStorageKey(params);
  const browserCookies = Object.fromEntries(
    document.cookie.split('; ').map(v => v.split(/=(.*)/s).map(decodeURIComponent)),
  );

  const previousResult = browserCookies[key] || null;

  const isPreviousResultValid = params.tests.some(test => test.value === previousResult);
  if (isPreviousResultValid) return previousResult;

  return null;
}

function validateTestParameters(params: GetSsrABTestParams): void {
  const percentageSum = params.tests.reduce((sum, test) => {
    return sum + test.percentage;
  }, 0);
  if (percentageSum !== 1) {
    throw new Error('getSsrABTest(): test percentages must add up to one');
  }

  if (!params.tests.some(test => test.value === params.fallback)) {
    throw new Error('getSsrABTest(): fallback value must be a valid test value');
  }
}

function performCleanupPreviousTests(
  params: GetSsrABTestParams,
  cookiesInstance: CookiesType,
): void {
  for (let i = 1; i < params.version; i++) {
    const keyToCleanup = `${params.key}_${i}`;
    cookiesInstance.set(keyToCleanup); // no value passed to clear it out
  }
}

function getStorageKey(params: GetSsrABTestParams): string {
  return `${params.key}_${params.version}`;
}

function getTestResult(params: GetSsrABTestParams): string {
  let currentPercentageFloor = 0;
  let result = params.fallback;

  const random = Math.random();

  for (const test of params.tests) {
    currentPercentageFloor += test.percentage;
    if (random < currentPercentageFloor) {
      result = test.value;
      break;
    }
  }

  return result;
}
