import Image from 'next/image';
import styled from 'styled-components';

const Text = styled.p`
  color: #ffffff;
  margin-right: 0rem;
  margin: 0;
  padding-bottom: 0.5rem;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

const Line = styled.div`
  width: 35px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.2);
  margin-bottom: 24px;
`;

export const CopyrightText: React.FC = () => {
  return (
    <div>
      <Line />
      <Text>&copy; {new Date().getFullYear()} Brain.FM, Inc.</Text>
      <Text>
        Made with{' '}
        <Image
          alt={'heart'}
          height={12}
          src={'https://cdn.brain.fm/icons/heart_pink.svg'}
          width={14}
        />{' '}
        in NYC
      </Text>
    </div>
  );
};
