import Image, { ImageProps } from 'next/image';
import styled from 'styled-components';

import { AnalyticsEvent } from '../../utils/analytics';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 2.5rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(64px);
  border-radius: 4rem;
  padding: 1.5rem;
  transition: background 0.3s ease-in-out;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const Text = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
  margin: 0 0.5rem;
`;

type AuthButtonProps = Pick<ImageProps, 'src' | 'alt'> & {
  label?: string;
  analyticsEvent: AnalyticsEvent;
};

export const AuthButton: React.FC<AuthButtonProps> = ({ src, alt, label, analyticsEvent }) => {
  return (
    <Container data-analytics-event={JSON.stringify(analyticsEvent)}>
      <Image alt={alt} height={25} src={src} width={25} />
      {label && <Text>{label}</Text>}
    </Container>
  );
};
