import styled from 'styled-components';

import Button, { ButtonProps } from './Button';

export const BaseButtonContainer = styled(Button)<Partial<ButtonProps>>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  border-radius: 100px;
  border: 1px solid #fff;
  box-shadow: 0 2.5px 11.5px rgba(147, 35, 102, 0.4);
  font-family: ${({ theme }) => theme.fontFamily.bold};
  color: #191736;
  padding: calc(1rem - 2px) calc(3rem - 2px);
  transition:
    background 0.2s ease-in-out,
    color 0.15s ease-in-out;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : `fit-content`)};
  font-size: 0.75rem;
  &:hover {
    background: rgba(255, 255, 255, 0.8);
  }

  &:disabled {
    background: #c0c0c0;
    opacity: 0.7;
    border-color: #c0c0c0;
  }
`;

export const BaseButton: React.FC<ButtonProps> = ({
  children,
  analyticsEvent,
  onClick,
  isLoading = false,
  isFullWidth,
  testId,
}) => {
  return (
    <BaseButtonContainer
      data-analytics-event={JSON.stringify(analyticsEvent)}
      data-testid={testId}
      isFullWidth={isFullWidth}
      isLoading={isLoading}
      onClick={isLoading ? undefined : onClick}
    >
      {isLoading ? <Loading /> : children}
    </BaseButtonContainer>
  );
};

// quick and easy because i had five minutes
// - dan p <3
const LoadingContainer = styled.div`
  animation: shimmer 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;

  @keyframes shimmer {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

function Loading() {
  return <LoadingContainer>Submitting...</LoadingContainer>;
}
