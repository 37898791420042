import React, { useContext } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import { ModalDispatchContext, ModalStateContext } from '../../context/modalContext';
import { mediaQuery } from '../../styles/global';
import { modalStyle } from './modalStyle';

Modal.setAppElement('#__next');

const DesktopVideoFrame = styled.div`
  ${mediaQuery.maxWidth.desktop} {
    display: none;
  }
`;

const MobileVideoFrame = styled.div`
  ${mediaQuery.minWidth.desktop} {
    display: none;
  }
`;

export function HowItWorksVideoModal() {
  const modalState = useContext(ModalStateContext);
  const setModalState = useContext(ModalDispatchContext);

  const onClose = () => {
    setModalState({ howItWorksVideoVisible: false });
  };

  return (
    <Modal isOpen={modalState.howItWorksVideoVisible} style={modalStyle} onRequestClose={onClose}>
      <DesktopVideoFrame>
        <iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          frameBorder="0"
          height="730"
          src="https://youtube.com/embed/OZnruOLY9X4"
          title="YouTube video player"
          width="1120"
        />
      </DesktopVideoFrame>
      <MobileVideoFrame>
        <iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          frameBorder="0"
          height="220"
          src="https://youtube.com/embed/OZnruOLY9X4"
          title="YouTube video player"
          width="392"
        />
      </MobileVideoFrame>
    </Modal>
  );
}
