import Link from 'next/link';
import styled from 'styled-components';

import { NavigationLinkText } from './NavigationLinkText';

const StyledLink = styled.a`
  text-decoration: none;
  margin: 0;
  padding: 0;
`;

export interface NavigationLinkProps {
  type?: 'headerLink' | 'footerLink';
  /** link text */
  text: string;
  /** href for next/link */
  link: string;
  /** link action for analytics */
  action: string;
  /** if the link points to external resource */
  external?: boolean;
}

export const NavigationLink: React.FC<NavigationLinkProps> = ({
  text,
  link,
  action,
  external,
  type = 'headerLink',
}) => {
  const analyticsEvent = JSON.stringify({ category: 'Navigation', action });

  const textStyle = type === 'footerLink' ? { fontSize: '20px' } : {};
  const linkStyle =
    type === 'headerLink'
      ? { marginBottom: 0, color: 'rgba(255, 255, 255, 0.75)' }
      : { marginBottom: '0.75rem' };

  if (external) {
    return (
      <StyledLink
        data-analytics-event={analyticsEvent}
        href={link}
        style={linkStyle}
        target="_blank"
      >
        <NavigationLinkText style={textStyle}>{text}</NavigationLinkText>
      </StyledLink>
    );
  }

  return (
    <StyledLink as="div" data-analytics-event={analyticsEvent} style={linkStyle}>
      <Link href={link} passHref>
        <NavigationLinkText style={textStyle}>{text}</NavigationLinkText>
      </Link>
    </StyledLink>
  );
};
