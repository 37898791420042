import dynamic from 'next/dynamic';
import React from 'react';
import styled from 'styled-components';

import { PromoBannerType } from '../../pages/api/promoBanner';
import { mediaQuery } from '../../styles/global';
import { DeviceTypes } from '../../utils/getDeviceType';
import { BrandLogo } from '../_core/BrandLogo';
import MaxWidth from '../shared/MaxWidth';
import { DownloadMacAppBanner } from './components/DownloadMacAppBanner';
import { PartnerBanner, PartnerType } from './components/PartnerBanner';
import { PromoBanner } from './components/PromoBanner';
import * as S from './Header.styles';
import { HeaderActionButton } from './HeaderActionButton';

type Props = {
  headerActionsAvailable: boolean;
  partner?: PartnerType;
  promoBanner: PromoBannerType | null;
  isDownloadPage: boolean;
  isScrolled: boolean;
  downloadMacAppBannerVisible: boolean;
  onDownloadMacAppClose: () => void;
  onDownloadMacAppNavigate: () => void;
  deviceType: DeviceTypes;
  isUserLoggedIn: boolean;
  onNavigateToApp: () => void;
  onClickSignIn: () => void;
  onClickSignUp: () => void;
  onClickDownload: () => void;
};

const Navigation = dynamic(() => import('./Navigation'), {
  ssr: true,
});

const HeaderActions = dynamic(() => import('./components/HeaderActions'), {
  ssr: false,
});

const SignInButtonWrapper = styled.div`
  ${mediaQuery.maxWidth.phone} {
    display: none;
  }
`;

export function HeaderDisplay(props: Props): JSX.Element {
  return (
    <S.Container>
      {props.partner ? <PartnerBanner partner={props.partner} /> : null}
      {!props.partner && props.promoBanner ? <PromoBanner data={props.promoBanner} /> : null}

      {!props.partner && !props.promoBanner && props.downloadMacAppBannerVisible ? (
        <DownloadMacAppBanner
          onClose={props.onDownloadMacAppClose}
          onNavigate={props.onDownloadMacAppNavigate}
        />
      ) : null}

      <S.HeaderDiv isScrolled={props.isScrolled}>
        <MaxWidth style={{ maxWidth: props.isDownloadPage ? '1220px' : '1328px' }}>
          <S.HeaderWrapper>
            <S.LeftContainer>
              <BrandLogo analytics={{ category: 'Header', action: 'Brand Header Click' }} />
              <Navigation />
            </S.LeftContainer>
            <S.RightContainer>
              {props.headerActionsAvailable && (
                <HeaderActions
                  deviceType={props.deviceType}
                  isUserLoggedIn={props.isUserLoggedIn}
                  onClickDownload={props.onClickDownload}
                  onClickSignIn={props.onClickSignIn}
                  onClickSignUp={props.onClickSignUp}
                  onNavigateToApp={props.onNavigateToApp}
                />
              )}
            </S.RightContainer>
          </S.HeaderWrapper>
        </MaxWidth>
      </S.HeaderDiv>
    </S.Container>
  );
}

export function HeaderSignInSignUpActionsDisplay(props: {
  onClickSignIn: () => void;
  onClickSignUp: () => void;
}): JSX.Element {
  return (
    <>
      <SignInButtonWrapper>
        <HeaderActionButton
          analyticsEvent={{ category: 'Header', action: 'Sign In Button Click' }}
          hasBorder={false}
          testId="sign-in"
          onClick={props.onClickSignIn}
        >
          SIGN IN
        </HeaderActionButton>
      </SignInButtonWrapper>
      <HeaderActionButton
        analyticsEvent={{ category: 'Header', action: 'Signup Button Click' }}
        hasBorder
        testId="sign-up"
        onClick={props.onClickSignUp}
      >
        TRY BRAIN.FM FOR FREE
      </HeaderActionButton>
    </>
  );
}

export function HeaderSignedInActionsDisplay(props: { onGoToWebApp: () => void }): JSX.Element {
  return (
    <>
      <HeaderActionButton
        analyticsEvent={{ category: 'Header', action: 'Go To Web App Click' }}
        hasBorder
        testId="goToWebAppButton"
        onClick={props.onGoToWebApp}
      >
        GO TO WEB APP
      </HeaderActionButton>
    </>
  );
}

export function HeaderSignInDownloadsDisplay(props: { onClickDownload: () => void }): JSX.Element {
  return (
    <>
      <HeaderActionButton
        analyticsEvent={{ category: 'Header', action: 'Download Button Click' }}
        onClick={props.onClickDownload}
      >
        DOWNLOAD
      </HeaderActionButton>
    </>
  );
}
