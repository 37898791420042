import styled from 'styled-components';

export const NavigationLinkText = styled.div`
  transition: color 0.2s ease-in-out;
  cursor: pointer;
  display: inline-block;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  inline-size: max-content;
  color: #898e9d;

  &:hover {
    color: #fff;
  }
`;
