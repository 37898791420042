/*
  test example:

  export const HERO_BUTTONS_SSO: UseLocalABTestParams = {
    fallback: 'off',
    key: 'ab_hero_buttons_sso_july2023',
    version: 1,
    tests: [
      {
        percentage: 0.5,
        value: 'on',
      },
      {
        percentage: 0.5,
        value: 'off',
      },
    ],
  };
*/

import {BROWSER_ENVIRONMENT} from '../globals';
import { UseLocalABTestParams } from './useLocalABTest';

export const DOWNLOAD_MAC_BANNER_OR_MODAL: UseLocalABTestParams = {
  fallback: 'modal',
  key: 'download_mac_banner_or_modal',
  version: 2,
  tests: [
    {
      percentage: BROWSER_ENVIRONMENT === 'development' ? 0.5 : 0,
      value: 'modal',
    },
    {
      percentage: BROWSER_ENVIRONMENT === 'development' ? 0.5 : 0,
      value: 'banner',
    },
    {
      percentage: BROWSER_ENVIRONMENT === 'development' ? 0 : 1,
      value: 'off',
    },
  ],
};
