import { useEffect, useState } from 'react';

/**
 * Helps to smoothly scroll to a secrion on the page.
 * You have to provide a valid ID that is already set as an anchor on the page.
 * @param hash id on the page (e.g <div id="someId" />)
 */
export const useScrollTo = (hash: string): (() => void) => {
  const [state, setScrollTo] = useState({ scrollTo: () => console.warn('Scroll position not found') });

  useEffect(() => {
    const onLoad = () => {
      const section = document.getElementById(hash);

      if (!section) {
        console.warn('Provided ID does not exist in this page');
      } else {
        const top = section.getBoundingClientRect().top + window.scrollY;
        setScrollTo({
          scrollTo: () => {
            window.scroll({ top, behavior: 'smooth' });
          },
        });
      }
    };

    if (document.readyState === 'complete') {
      onLoad();
    } else {
      window.addEventListener('load', onLoad);
      return () => document.removeEventListener('load', onLoad);
    }
  }, [hash]);

  return state.scrollTo;
};
