import Image from 'next/image';
import styled from 'styled-components';

import { AnalyticsEvent } from '../../utils/analytics';

const ICON_SIZE = 24;

const Container = styled.a`
  // display: block;
  color: ${({ theme }) => theme.colors.white};
  padding: 0.5rem 0;
  width: 1.75rem;
  margin-right: 1rem;
  opacity: 0.8;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

export interface MediaLinkProps {
  /** name for alt image or icon */
  name: string;
  /** the url */
  link: string;
  /** image path for next/image */
  iconPath: string;
  /** event for analytics tracking */
  analyticsEvent: AnalyticsEvent;
}

export const MediaLink: React.FC<MediaLinkProps> = ({ name, link, iconPath, analyticsEvent }) => {
  return (
    <Container
      data-analytics-event={JSON.stringify(analyticsEvent)}
      href={link}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Image alt={name} height={ICON_SIZE} loading="lazy" src={iconPath} width={ICON_SIZE} />
    </Container>
  );
};
